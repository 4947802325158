var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-xl overflow-hidden mx-auto"},[_c('v-toolbar',{staticClass:"primary",attrs:{"tag":"div","flat":"","dark":"","tile":""}},[_c('v-toolbar-title',[_vm._v(" All Notifications ")]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"white","loading":_vm.checking,"disabled":_vm.checking},on:{"click":_vm.checkAll}},[_vm._v("Read All")])],1),_c('v-card-text',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.allnotifications,"loading":_vm.loading,"server-items-length":_vm.totalallnotifications,"options":_vm.options,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'font-weight-bold': !item.readed }},[_vm._v(" "+_vm._s(item.content))])]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTo(item))+" ")]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdBy ? item.createdBy.fullname : "-")+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.createdAt))+" ")]}},{key:"item.readed",fn:function(ref){
var item = ref.item;
return [(item.readed)?[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])]:[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"success","disabled":item.readed,"x-small":"","icon":""},on:{"click":function($event){return _vm.readNotification(item.uuid)}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Read Notification")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","x-small":"","icon":""},on:{"click":function($event){return _vm.toDelteNotify(item.uuid)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Notification")])])]:_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"400px","transition":"dialog-transition"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"error white--text"},[_vm._v(" Delete Notification ")]),_c('v-card-text',{staticClass:"mt-4 text-center"},[_c('h4',[_vm._v("Are you sure you want delete this notification?")])]),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"disabled":_vm.loading,"text":""},on:{"click":_vm.cancelDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"error","loading":_vm.loading,"disabled":_vm.loading,"text":""},on:{"click":_vm.confirmDelte}},[_vm._v("Delete")])],1)],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }