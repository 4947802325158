<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card class="rounded-xl overflow-hidden mx-auto">
    <v-toolbar class="primary" tag="div" flat dark tile>
      <v-toolbar-title> All Notifications </v-toolbar-title>
      <v-spacer />
      <v-btn outlined color="white" @click="checkAll" :loading="checking" :disabled="checking">Read All</v-btn>
    </v-toolbar>
    <v-card-text>
      <template>
        <v-data-table :headers="headers" :items="allnotifications" :loading="loading"
          :server-items-length="totalallnotifications" :options.sync="options" :footer-props="footerProps"
          class="elevation-1">
          <template v-slot:[`item.content`]="{ item }">
            <span :class="{ 'font-weight-bold': !item.readed }"> {{ item.content }}</span>
          </template>
          <template v-slot:[`item.owner`]="{ item }">
            {{ getTo(item) }}
          </template>
          <template v-slot:[`item.createdBy`]="{ item }">
            {{ item.createdBy ? item.createdBy.fullname : "-" }}
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ getDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.readed`]="{ item }">
            <template v-if="item.readed"><v-icon color="success">mdi-check</v-icon></template>
            <template v-else>
              <v-icon color="error">mdi-close</v-icon>
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <template v-if="item">

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="success" :disabled="item.readed" class="mr-2" v-on="on" x-small icon
                    @click="readNotification(item.uuid)"><v-icon>mdi-check</v-icon></v-btn>
                </template>
                <span>Read Notification</span>
              </v-tooltip>


              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="error" v-on="on" x-small icon
                    @click="toDelteNotify(item.uuid)"><v-icon>mdi-delete</v-icon></v-btn>
                </template>
                <span>Delete Notification</span>
              </v-tooltip>
            </template>
          </template>
        </v-data-table>
        <v-dialog v-model="deleteDialog" persistent :overlay="false" max-width="400px" transition="dialog-transition">
          <v-card>
            <v-card-title class="error white--text">
              Delete Notification
            </v-card-title>
            <v-card-text class="mt-4 text-center">
              <h4>Are you sure you want delete this notification?</h4>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn @click="cancelDelete" :disabled="loading" text>Cancel</v-btn>
              <v-btn color="error" :loading="loading" :disabled="loading" text @click="confirmDelte">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import { utilMixin } from "@/utils/mixins";
import { mapActions, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo } from "@/components/Notification";
export default {
  name: "notifications-view",
  mixins: [utilMixin],
  data() {
    return {
      headers: [
        {
          text: "Content",
          align: "start",
          sortable: false,
          value: "content",
        },
        {
          text: "To",
          align: "start",
          sortable: false,
          value: "owner",
        },
        {
          text: "Created by",
          align: "start",
          sortable: false,
          value: "createdBy",
        },
        {
          text: "Created at",
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Readed",
          align: "start",
          sortable: false,
          value: "readed",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      footerProps: {
        "items-per-page-options": [25,50, 100,200, 500],
      },
      options: {},
      deleteDialog: false,
      uuidNotify: null,
      loading: false,
      checking:false,
      range: {
      limit: 100,
      offset: 0,
      },
    };
  },
  computed: {
    ...mapState(["allnotifications",'totalallnotifications']),
  },
  watch:{
    options(val) {
    if (val != {}) {
    this.getNotify();    
    }
    },
  },
  methods: {
    ...mapActions(["actGetAllNotifications"]),
    getTo(item) {
      if (item.owner) {
        return item.owner.fullname;
      }
      if (item.aboutLead) {
        return item.aboutLead.name + " " + item.aboutLead.last_name;
      }
      if (item.aboutPatient) {
        return item.aboutPatient.fullname;
      }
    },
    toDelteNotify(value) {
      this.deleteDialog = true;
      this.uuidNotify = value;
    },
    cancelDelete() {
      this.deleteDialog = false;
      this.uuidNotify = null;
    },
    readNotification(value) {

    getAPI
    .post("/notify/read/" + value)
    .then(() => {
    this.getNotify()
    })
    .catch((err) => {
    let mess = err.response.data.message.toString();
    
    notifyError(err.response.data, `An error occurred: ${mess}`);
    });
    },
    readNotifications(value) {

    getAPI
    .post("/notify/read/" + value)
    .then(() => {
    //
    })
    .catch((err) => {
    let mess = err.response.data.message.toString();
    
    notifyError(err.response.data, `An error occurred: ${mess}`);
    });
    },
    async checkAll() {   
    this.checking=true;
    for (let index = 0; index < this.allnotifications.length; index++) {
      const element = this.allnotifications[index];
      if (!element.readed) {      
        await this.readNotifications(element.uuid);    
      }
    }
    this.checking=false;
    this.getNotify();
    },
    confirmDelte() {
      this.loading = true;

      getAPI
        .delete("/notify/delete/" + this.uuidNotify)
        .then(() => {
          this.loading = false;
          this.cancelDelete();
          notifyInfo("Notification has been delete");
          this.getNotify();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    async getNotify(){
     
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 50;
      let body = this.range;
     
      body = {
        limit: itmper,
        offset: (page - 1) * itmper,
        };
        
        this.loading = true;
    await this.actGetAllNotifications(body);
    this.loading = false;
    }
  },
  mounted() {
   // this.getNotify()
  },
};
</script>
<style lang="scss" scoped></style>
